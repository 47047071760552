import { renderToStaticMarkup } from 'react-dom/server'
import { Pencil } from 'lucide-react'

export const DateFormat = {
  serialization: {
    date: 'yyyy-MM-dd',
    // dateTime: 'yyyy-MM-ddTHH:mm:ss'
    dateTime: 'yyyy-MM-dd HH:mm:ss'
  },
  display: {
    date: 'dd.MM.yyyy',
    dateTime: 'dd.MM.yyyy HH:mm:ss'
  }
}

export const NumberFormat = {
  display: '#,##0.00'
}

export const DataGridSetup = {
  column: {
    baseProps: {
      number: (editorOptions) => ({
        dataType: 'number',
        width: 150,
        format: NumberFormat.display,
        editorOptions
      }),
      date: () => ({
        dataType: 'date',
        width: 100,
        format: DateFormat.display.date,
        editorOptions: {
          // dateSerializationFormat: DateFormat.serialization.date,
          displayFormat: DateFormat.display.date
        }
      }),
      dateTime: () => ({
        dataType: 'datetime',
        format: DateFormat.display.dateTime,
        editorOptions: {
          // dateSerializationFormat: DateFormat.serialization.dateTime,
          displayFormat: DateFormat.display.dateTime
        }
      })
    }
  }
}

DataGridSetup.column.baseProps.positiveNumber = (editorOptions) => ({
  ...DataGridSetup.column.baseProps.number(),
  precision: 0,
  editorOptions: {
    min: 0,
    ...editorOptions
  }
})

DataGridSetup.column.baseProps.percentNumber = (editorOptions) => ({
  ...DataGridSetup.column.baseProps.number(),
  editorOptions: {
    min: 0,
    max: 100,
    ...editorOptions
  }
})

export const FormSetup = {
  item: {
    baseProps: {
      error: (error = null) => ({
        editorOptions: {
          isValid: !error,
          validationError: { message: error }
        }
      }),
      date: (error = null) => ({
        editorOptions: {
          dateSerializationFormat: DateFormat.serialization.date,
          displayFormat: DateFormat.display.date,
          isValid: !error,
          validationError: { message: error }
        }
      }),
      dateTime: (error = null) => ({
        editorOptions: {
          type: 'datetime',
          dateSerializationFormat: DateFormat.serialization.dateTime,
          displayFormat: DateFormat.display.dateTime,
          isValid: !error,
          validationError: { message: error }
        }
      })
    }
  }
}

export const buttonLink = {
  renderLink: (href) => ({
    render: ({ icon, text }) => (
      <a
        className='!text-inherit dx-button-content'
        href={href}
        onClick={e => e.preventDefault()}
      >
        {!!icon &&
          <i className='dx-icon dx-svg-icon' dangerouslySetInnerHTML={{ __html: icon }} />}

        <span className='dx-button-text'>{text}</span>
      </a>
    ),
    elementAttr: {
      class: 'custom-button-link'
    }
  })
}

export const gridButtonLink = (data) => {
  return (
    <a
      className='!text-inherit inline-block'
      href={data.href}
      onClick={e => e.preventDefault()}
      title={data.hint}
    >
      <i
        className='dx-icon dx-svg-icon dx-link dx-link-icon'
        dangerouslySetInnerHTML={{
          __html: data.icon ?? renderToStaticMarkup(<Pencil size={16} />)
        }}
      />
    </a>
  )
}
