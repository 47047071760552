import CustomStore from 'devextreme/data/custom_store'
import { DataSourceCallbacks, route } from '../utils'
import links from '../links.json'

export const defaultDataSource = {
  bank: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.banks.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.banks.resource, { bank: key }))
    }
  }),

  currency: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.currencies.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.currencies.resource, { currency: key }))
    }
  }),

  postCode: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.postCodes.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.postCodes.resource, { postCode: key }))
    }
  }),

  city: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.cities.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.cities.resource, { city: key }))
    }
  }),

  country: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.countries.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.countries.resource, { country: key }))
    }
  }),

  responsibility_center: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.responsibilityCenters.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.responsibilityCenters.resource, { responsibilityCenter: key }))
    }
  }),

  bank_account: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(
      { ...options, ...(config.params ?? {}) },
      links.administration.bankAccounts.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.bankAccounts.resource, { bankAccount: key }))
    }
  }),

  language: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.languages.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.languages.resource, { language: key }))
    }
  }),

  unitOfMeasures: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.unitOfMeasures.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.unitOfMeasures.resource, { unitOfMeasure: key }))
    }
  }),

  measurementGroup: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.measurementGroups.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.measurementGroups.resource, { measurementGroup: key }))
    }
  }),

  vatProductPostingGroup: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.vatProductPostingGroups.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.vatProductPostingGroups.resource, { vatProductPostingGroup: key }))
    }
  }),

  vatBusinessPostingGroup: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.vatBusinessPostingGroups.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.vatBusinessPostingGroups.resource, { vatBusinessPostingGroup: key }))
    }
  }),

  brand: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.products.brands.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.products.brands.resource, { brand: key }))
    }
  }),

  remittance: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.remittances.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.remittances.resource, { remittance: key }))
    }
  }),

  manufacturer: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.products.manufacturers.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.products.manufacturers.resource, { manufacturer: key }))
    }
  }),

  productCategory: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.products.categories.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.products.categories.resource, { category: key }))
    }
  }),

  municipality: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.municipalities.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.municipalities.resource, { municipality: key }))
    }
  }),

  paymentTerm: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.paymentTerms.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.paymentTerms.resource, { paymentTerm: key }))
    }
  }),

  paymentMethod: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.paymentMethods.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.paymentMethods.resource, { paymentMethod: key }))
    }
  }),

  shipmentMethod: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.shipmentMethods.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.shipmentMethods.resource, { shipmentMethod: key }))
    }
  }),

  shipmentAgent: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.shipmentAgents.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.shipmentAgents.resource, { shipmentAgent: key }))
    }
  }),

  transportMethod: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.transportMethods.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.transportMethods.resource, { transportMethod: key }))
    }
  }),

  location: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load({ ...options, ...(config.params ?? {}) }, links.administration.locations.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.locations.resource, { location: key }))
    }
  }),

  user_location: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load({ ...options, ...(config.params ?? {}) }, route(links.administration.users.locations.getGridOptions, { user: config.user.id })),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.locations.resource, { location: key }))
    }
  }),

  religion: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.hr.religions.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.hr.religions.resource, { religion: key }))
    }
  }),

  nationality: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.hr.nationalities.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.hr.nationalities.resource, { nationality: key }))
    }
  }),

  citizenship: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.hr.citizenship.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.hr.citizenship.resource, { citizenship: key }))
    }
  }),

  jobTitleCategory: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.hr.jobTitleCategories.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.hr.jobTitleCategories.resource, { jobTitleCategory: key }))
    }
  }),

  jobTitle: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.hr.jobTitles.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.hr.jobTitles.resource, { jobTitle: key }))
    }
  }),

  user: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.users.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.users.resource, { user: key }))
    }
  }),

  organizationalUnit: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.hr.organizationalUnits.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.hr.organizationalUnits.resource, { organizationalUnit: key }))
    }
  }),

  deductionGroup: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.salary.deductionGroups.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.salary.deductionGroups.resource, { deductionGroup: key }))
    }
  }),

  organizationalUnitType: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.hr.organizationalUnitTypes.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.hr.organizationalUnitTypes.resource, { organizationalUnitType: key }))
    }
  }),

  employmentContract: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.hr.employmentContracts.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.hr.employmentContracts.resource, { employmentContract: key }))
    }
  }),

  productDiscountGroup: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.productDiscountGroups.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.productDiscountGroups.resource, { productDiscountGroup: key }))
    }
  }),

  accountDiscountGroup: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.accountDiscountGroups.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.accountDiscountGroups.resource, { accountDiscountGroup: key }))
    }
  }),

  product: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(
      { ...options, ...(config.params ?? {}) },
      route(links.products.getOptions, { 'product?': null })
    ),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.products.resource, { product: key }))
    }
  }),

  product_references: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, route(links.products.crossReferences.getOptions, { product: config.product.id }))
  }),

  product_replacements: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, route(links.products.replacements.getOptions, { product: config.product.id }))
  }),

  product_bom: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load({ ...options, ...(config.params ?? {}) }, route(links.products.bom.getOptions, { product: config.product.id })),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.products.resource, { product: key }))
    }
  }),

  product_uom_for_grid: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load({ ...options, ...(config.params ?? {}) }, route(links.products.uom.getOptionsForGrid, { product: config.product.id })),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.unitOfMeasures.resource, { unitOfMeasure: key }))
    }
  }),

  product_uom: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load({ ...options, ...(config.params ?? {}) }, route(links.products.uom.getOptions, { product: config.product.id })),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.unitOfMeasures.resource, { unitOfMeasure: key }))
    }
  }),

  product_vendor: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load({ ...options, ...(config.params ?? {}) }, route(links.products.vendors.getOptions, { product: config.product.id })),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.accounts.resource, { account: key }))
    }
  }),

  numberingSeries: (model, paramsFromQuery) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.numberingSeries.getOptions + '?' + new URLSearchParams(paramsFromQuery).toString()),
    byKey: key => {
      if (model?.id === key) {
        return model
      }
      return DataSourceCallbacks.byKey(route(links.administration.numberingSeries.resource, { numberingSeries: key }))
    }
  }),

  account: (config = {
    type: null
  }) => new CustomStore({
    key: 'id',
    load: (options) => {
      let url = null

      if (config.type) {
        url = links.accounts.getOptions + '?' + new URLSearchParams({ type: config.type })
      } else {
        url = links.accounts.getOptions
      }

      return DataSourceCallbacks.load({ ...options, ...(config.params ?? {}) }, url)
    },
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.accounts.resource, { account: key }))
    }
  }),

  role: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.roles.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.roles.resource, { role: key }))
    }
  }),

  fixedAsset: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(
      { ...options, ...(config.params ?? {}) },
      links.fixedAssets.getOptions
    ),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.fixedAssets.resource, { fixedAsset: key }))
    }
  }),

  fixedAssetClass: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.fixedAssets.fixedAssetClasses.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.fixedAssets.fixedAssetClasses.resource, { fixedAssetClass: key }))
    }
  }),

  fixedAssetLocation: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.fixedAssets.fixedAssetLocations.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.fixedAssets.fixedAssetLocations.resource, { fixedAssetLocation: key }))
    }
  }),

  education: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.hr.educations.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.hr.educations.resource, { education: key }))
    }
  }),

  educationDegree: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.hr.educationDegrees.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.hr.educationDegrees.resource, { educationDegree: key }))
    }
  }),

  bachelorDegree: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.hr.bachelorDegrees.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.hr.bachelorDegrees.resource, { bachelorDegree: key }))
    }
  }),

  modelLifeCycle: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.modelLifeCycles.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.modelLifeCycles.resource, { modelLifeCycle: key }))
    }
  }),

  serviceZone: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.services.serviceZones.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.services.serviceZones.resource, { serviceZone: key }))
    }
  }),

  serviceGroup: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.services.serviceGroups.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.services.serviceGroups.resource, { serviceGroup: key }))
    }
  }),

  project: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.pms.projects.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.pms.projects.resource, { project: key }))
    }
  }),

  salesPersonPurchaser: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.salesPersonPurchasers.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.salesPersonPurchasers.resource, { salesPersonPurchaser: key }))
    }
  }),

  accountLocation: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.accounts.locations.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.accounts.locations.resource, { accountLocation: key }))
    }
  }),

  region: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.regions.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.regions.resource, { region: key }))
    }
  }),

  resourceGroup: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.resources.resourceGroups.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.resources.resourceGroups.resource, { resourceGroup: key }))
    }
  }),

  workType: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.resources.workTypes.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.resources.workTypes.resource, { workType: key }))
    }
  }),

  employee: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(
      { ...options, ...(config.params ?? {}) },
      links.hr.employees.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.hr.employees.resource, { employee: key }))
    }
  }),

  smallInventory: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(
      { ...options, ...(config.params ?? {}) },
      links.fixedAssets.smallInventories.getOptions
    ),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.fixedAssets.smallInventories.resource, { smallInventory: key }))
    }
  }),

  resource: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(
      { ...options, ...(config.params ?? {}) },
      links.resources.getOptions
    ),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.resources.resource, { resource: key }))
    }
  }),

  symptomCode: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.services.symptomCodes.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.services.symptomCodes.resource, { symptomCode: key }))
    }
  }),

  faultCode: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.services.faultCodes.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.services.faultCodes.resource, { faultCode: key }))
    }
  }),

  solutionCode: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.services.solutionCodes.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.services.solutionCodes.resource, { solutionCode: key }))
    }
  }),

  resourceSkill: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.resources.resourceSkills.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.resources.resourceSkills.resource, { resourceSkill: key }))
    }
  }),

  helpTopic: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.helpDesk.helpTopics.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.helpDesk.helpTopics.resource, { helpTopic: key }))
    }
  }),

  cashAccount: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(
      { ...options, ...(config.params ?? {}) },
      links.finance.cashAccounts.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.finance.cashAccounts.resource, { cashAccount: key }))
    }
  }),

  personalTaxGroup: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.personalTaxGroups.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.personalTaxGroups.resource, { personalTaxGroup: key }))
    }
  }),

  personalTaxCategory: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.administration.personalTaxCategories.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.personalTaxCategories.resource, { personalTaxCategory: key }))
    }
  }),

  company: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(
      { ...options, ...(config.params ?? {}) },
      links.administration.company.getOptions
    ),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(links.administration.company.resource)
    }
  }),

  modelLifeCyclePhase: (
    config
  ) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(
      options,
      route(links.administration.modelLifeCycles.phases.getOptions, { modelLifeCycle: config.modelLifeCycle.id })
    )
  }),

  chartOfAccount: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(
      { ...options, ...(config.params ?? {}) },
      links.accounting.chartOfAccounts.getOptions
    ),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.accounting.chartOfAccounts.resource, { chartOfAccount: key }))
    }
  }),

  product_location_info: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, route(links.products.locationInfo.getGridOptions, { product: config.product.id })),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.administration.locations.resource, { location: key }))
    }
  }),

  yearlySalaryParameter: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.salary.yearlySalaryParameters.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.salary.yearlySalaryParameters.resource, { yearlySalaryParameter: key }))
    }
  }),

  monthlySalaryParameter: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.salary.monthlySalaryParameters.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.salary.monthlySalaryParameters.resource, { monthlySalaryParameter: key }))
    }
  }),

  salaryLiabilityType: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.salary.salaryLiabilityTypes.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.salary.salaryLiabilityTypes.resource, { salaryLiabilityType: key }))
    }
  }),

  salaryObligatoryType: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.salary.salaryObligatoryTypes.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.salary.salaryObligatoryTypes.resource, { salaryObligatoryType: key }))
    }
  }),

  document_tracking_parts: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(
      {
        ...options,
        ...(config.params ?? {})
      },
      route(links.documents.trackingParts.getOptions, {
        documentHeader: config.documentHeader.id,
        documentTypeBind: config.documentTypeBind.link
      })
    ),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }

      return DataSourceCallbacks.byKey(route(links.documents.trackingParts.resource, {
        documentHeader: config.documentHeader.id,
        documentTypeBind: config.documentTypeBind.link,
        documentTrackingPart: key
      }))
    }
  }),

  workstation: (config) => new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.pos.workstations.getOptions),
    byKey: key => {
      if (config?.model?.id === key) {
        return config.model
      }
      return DataSourceCallbacks.byKey(route(links.pos.workstations.resource, { workStation: key }))
    }
  })
}
